<template>
  <form class="w-full flex flex-column">
    <div class="col-12">
      <div
        v-if="computer.teamViewerVersion !== null || computer.teamViewerVersion !== '0'"
        class="
          card
          shadow-3
          flex flex-column
          align-items-start
          justify-content-center
          pl-4
          pt-3
          pb-3
          mb-0
        "
      >
        <h3 class="mb-3">
          <mdicon
            name="teamviewer"
            style="color: #2196f3"
            class="mr-2"
          />
          TeamViewer
        </h3>
        <div class="flex flex-row mt-1 mb-3">
          <label class="mr-2">Version:</label>
          <p class="">
            {{ computer.teamViewerVersion }}
          </p>
        </div>
        <div class="flex flex-row mb-1">
          <label class="mr-2">Client Id:</label>
          <p class="">
            {{ computer.teamViewerClientId }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div
        v-if="computer.anyDeskVersion !== null || computer.anyDeskVersion !== '0'"
        class="
          card
          shadow-3
          flex flex-column
          align-items-start
          justify-content-center
          pl-4
          pt-3
          pb-3
          mb-0
        "
      >
        <h3 class="mb-3">
          <img
            class="mr-2"
            src="../../assets/anydesk_icon.png"
            style="width: 20px"
          >AnyDesk
        </h3>
        <div class="flex flex-row mb-3 mt-1">
          <label class="mr-2">Version:</label>
          <p class="">
            {{ computer.anyDeskVersion }}
          </p>
        </div>
        <div class="flex flex-row mb-1">
          <label class="mr-2">Client Id:</label>
          <p class="">
            {{ computer.anyDeskClientId }}
          </p>
        </div>
        <div class="flex flex-row mt-2">
          <label class="mr-2">Installer prefix:</label>
          <p class="">
            {{ computer.installationPrefix }}
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
}
</script>
