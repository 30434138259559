
import ComputerRemoteAccessInfo from "@/components/computers/ComputerRemoteAccessInfo.vue"

export default {
  components: { ComputerRemoteAccessInfo },
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
}
